<template>
    <div>
        <div class="_w-max _mb-pad-top-50 _mb-pad-x-25" style="background-color:rgba(245, 245, 245, 1);">
            <div class="_w-max _white-bg _mb-pad-y-25 _mb-pad-x-25 _flex-row-mid _mb-mar-bottom-20" style="border-radius:10px;">
                <img v-if="logo" class="_mb-w-85 _mb-mar-right-25" :src="logo" alt="">
                <img v-else class="_mb-w-85 _mb-mar-right-25" src="~@/assets/image/hangda/shucai/sucaiavatar.png" alt="">
                <div>
                    <div class="_mb-font-32 _b _mb-mar-bottom-15" style="line-height:1;">{{userInfo.nickname||$t('未设置')}}</div>
                    <div class="_grey-99 _mb-font-24" >{{userInfo.email|| $t('未设置')}}</div>
                </div>
            </div>
            <div class="_w-max _white-bg _mb-pad-y-25 _mb-pad-x-25  _mb-mar-bottom-20" style="border-radius:10px;">
                <div class="_flex-row-bet-mid _mb-mar-bottom-35">
                    <div class="_mb-font-32 _b">{{$t('我的订单')}}</div>
                    <router-link :to="'/orderList'" class="_flex-row-mid" style="color:rgba(66, 132, 220, 1);">
                        <div class="_mb-mar-right-10 _mb-font-26">{{$t('所有订单')}}</div>
                        <div class="iconfont2 icon-xiangyou" style="font-size:3.46667vw;"></div>
                    </router-link>
                </div>
                <div class="grid-box">
                    <div @click="toOrder(80)" class="_flex-col-mid">
                        <div class="_mb-font-46 _mb-mar-bottom-15" style="color:rgba(66, 132, 220, 1);">{{userInfo.jiyunOrderStatusNum.status_80||0}}</div>
                        <div class="_mb-font-28">{{$t('已打包')}}</div>
                    </div>
                    <div @click="toOrder(85)" class="_flex-col-mid">
                        <div class="_mb-font-46 _mb-mar-bottom-15" style="color:rgba(66, 132, 220, 1);">{{userInfo.jiyunOrderStatusNum.status_85||0}}</div>
                        <div class="_mb-font-28">{{$t('已合包')}}</div>
                    </div>
                    <div @click="toOrder(190)" class="_flex-col-mid">
                        <div class="_mb-font-46 _mb-mar-bottom-15" style="color:rgba(66, 132, 220, 1);">{{userInfo.jiyunOrderStatusNum.status_90||0}}</div>
                        <div class="_mb-font-28">{{$t('已发货')}}</div>
                    </div>
                    <div @click="toOrder(100)" class="_flex-col-mid">
                        <div class="_mb-font-46 _mb-mar-bottom-15" style="color:rgba(66, 132, 220, 1);">{{userInfo.jiyunOrderStatusNum.status_100||0}}</div>
                        <div class="_mb-font-28">{{$t('国外已签收')}}</div>
                    </div>
                </div>
            </div>
            <div class="_w-max _white-bg _mb-pad-x-25  _mb-mar-bottom-25" style="border-radius:10px;">
                <div @click="showChangeName = true" class="_mb-pad-y-30 _flex-row-mid _border-bottom">
                    <div class="_flex-1 _flex-row-mid">
                        <img class="_mb-w-40 _mb-mar-right-20" src="~@/assets/image/hangda/logo/mobile-user-1.png" alt="">
                        <div class="_mb-font-28">{{$t('更改用户名')}}</div>
                    </div>
                    <div class="iconfont2 icon-xiangyou _mb-font-28"></div>
                </div>
                <router-link :to="'/changePwd'" class="_mb-pad-y-30 _flex-row-mid _border-bottom" style="color:unset;">
                    <div class="_flex-1 _flex-row-mid">
                        <img class="_mb-w-40 _mb-mar-right-20" src="~@/assets/image/hangda/logo/mobile-user-2.png" alt="">
                        <div class="_mb-font-28">{{$t('更改密码')}}</div>
                    </div>
                    <div class="iconfont2 icon-xiangyou _mb-font-28"></div>
                </router-link>
                <router-link :to="'/report'" class="_mb-pad-y-30 _flex-row-mid _border-bottom" style="color:unset;">
                    <div class="_flex-1 _flex-row-mid">
                        <img class="_mb-w-40 _mb-mar-right-20" src="~@/assets/image/hangda/logo/mobile-user-3.png" alt="">
                        <div class="_mb-font-28">{{$t('包裹预报')}}</div>
                    </div>
                    <div class="iconfont2 icon-xiangyou _mb-font-28"></div>
                </router-link>
                <router-link :to="'/mobile_report'" class="_mb-pad-y-30 _flex-row-mid _border-bottom" style="color:unset;">
                    <div class="_flex-1 _flex-row-mid">
                        <img class="_mb-w-40 _mb-mar-right-20" src="~@/assets/image/hangda/logo/mobile-user-3.png" alt="">
                        <div class="_mb-font-28">{{$t('我的包裹')}}</div>
                    </div>
                    <div class="iconfont2 icon-xiangyou _mb-font-28"></div>
                </router-link>
                <router-link :to="'/mb_list'" v-if="agentInfo.is_main" class="_mb-pad-y-30 _flex-row-mid _border-bottom" style="color:unset;">
                    <div class="_flex-1 _flex-row-mid">
                        <img class="_mb-w-40 _mb-mar-right-20" src="~@/assets/image/hangda/logo/mobile-user-4.png" alt="">
                        <div class="_mb-font-28">{{$t('申请打包')}}</div>
                    </div>
                    <div class="iconfont2 icon-xiangyou _mb-font-28"></div>
                </router-link>
                <div class="_mb-pad-y-30 _flex-row-mid " style="color:unset;">
                    <div class="_flex-1 _flex-row-mid" @click="toAddress">
                        <img class="_mb-w-40 _mb-mar-right-20" src="~@/assets/image/hangda/logo/mobile-user-5.png" alt="">
                        <div class="_mb-font-28">{{$t('地址管理')}}</div>
                    </div>
                    <div @click="toAddress" class="iconfont2 icon-xiangyou _mb-font-28"></div>
                </div>
            </div>
            <div @click="loginOut" class="_w-max  _text-center _flex-row-center-mid  _mb-mar-bottom-35 -mb-font-28 _mb-h-75" style="border-radius:6px;border:1px solid rgba(66, 132, 220, 1);color:rgba(66, 132, 220, 1);">
                {{$t('退出登录')}}
            </div>
        </div>
        <div v-if="showChangeName" class="_pos-fixed _top _left _h-max _w-max _flex-row-center-mid _mb-pad-x-50 _z-index-3" style="background-color:rgba(0,0,0,0.2);">
            <div class="_w-max _white-bg _mb-pad-x-40 _mb-pad-y-40" style="border-radius:8px;">
                <div class="_text-center _mb-font-36 _b _mb-mar-bottom-35">{{$t('更改用户名')}}</div>
                <div class="_mb-pad-x-25 _mb-pad-y-25 _mb-mar-bottom-35" style="border-radius:8px;background-color:rgba(247, 247, 247, 1);">
                    <input v-model="nickname" class="_w-max _mb-font-28" type="text" style="background-color:rgba(247, 247, 247, 1);" :placeholder="$t('请输入')">
                </div>
                <div class="_flex-row-mid">
                    <div @click="showChangeName = false" class="_flex-1 _flex-row-center-mid _mb-h-75 _mb-font-28 _mb-mar-right-25" style="border:1px solid rgba(66, 132, 220, 1);color:rgba(66, 132, 220, 1);border-radius:6px;">{{$t('取消')}} </div>
                    <div @click="submit" class="_flex-1 _flex-row-center-mid _mb-h-75 _mb-font-28 _white" style="border:1px solid rgba(66, 132, 220, 1);background-color:rgba(66, 132, 220, 1);border-radius:6px;">{{$t('确认')}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCookies, removeCookies, checkLogin } from "@/libs/util.js"
import { getUserInfo, userOrderList, bannerList ,changeInfo,submitLogout,editNickname} from '@/api/jiyun.js'
export default {
    name: 'Mobile_user',
    data() {
        return {
            metaInfo:{
                title: this.$t('page_title.user'),
                keyWords:'',
                description:''
            },
            userInfo: {},
            order_list: [],
            logo:'',
            nickname:'',
            showChangeName:false,
            agentInfo: {}
        }
    },
    watch:{
        showChangeName:{
            handler(){
                if(!this.showChangeName){
                    this.nickname = '';
                }
            },
        },
    },
    metaInfo() {
        return {
            title: this.metaInfo.title
        }
    },
    created(){
      
    },
    mounted() {
        let token = getCookies('web-token');
        if(token){
            this.getUserInfo();
            this.bannerList();
        }else{
            this.$Message.warning(this.$t('请先登录'));
            this.$router.push('/');
        }
    },
    methods: {
        // 退出登录
        loginOut() {
            this.$Modal.confirm({
                title: this.$t('退出登录确认'),
                content: this.$t('您确定退出当前账户吗？'),
                okText: this.$t('confirm'),
                cancelText: this.$t('cancel'),
                onOk(){
                    submitLogout().then((res)=>{
                        this.$Message.success(res.msg);
                        removeCookies('uid');
                        removeCookies('web-token');
                        removeCookies('web-expires_time');
                        this.userInfo = {};
                        location.reload();
                        this.$router.replace({
                            path: '/',
                        })
                    }).catch((err)=>{
                        this.$Message.error(err.msg)
                    })
                }
            })
        },
        // 代理banner
        bannerList(){
            let param = {
                domain_url: window.location.origin
            }
            bannerList(param).then((res)=>{
                this.agentInfo = res.data;
                this.logo = res.data.logo;
            }).catch((err)=>{
                this.$Message.error(err.msg)
            })
        },
        toAddress(){
            let that = this;
            if(false){
                this.$router.push({ 
                    name: 'ChangeAddress',
                    query: {uid: that.userInfo.uid}
                })
            }else{
                this.$router.push({ 
                    name: 'addressList',
                })
            }
        },
        toOrder(status){
            this.$router.push({ 
                name: 'orderList',
                query: {status}
            })
        },
        // 获取用户信息
        getUserInfo(){
            getUserInfo().then((res)=>{
                this.userInfo = res.data;
            }).catch((err)=>{
                this.$Message.error(err.msg)
            })
        },
        // 提交
        submit(){
            let param = {
                nickname: this.nickname,
                domain_url: window.location.origin
            }
            editNickname(param).then((res)=>{
                this.$Message.success(res.msg);
                this.showChangeName = false;
                this.getUserInfo();
            }).catch((err)=>{
                this.$Message.error(err.msg)
            })
        },
    },
};
</script>

<style scoped>
.grid-box{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
</style>